/****************************** components ******************************/
@import "./components/mixin";
@import "./components/color"; // 颜色
@import "./components/default";
@import "./components/normalize";
@import "./components/reset";
@import "./components/grid";
//@import "./components/case";//例子
/****************************** style ******************************/

body,
button,
input,
select,
textarea {
    font: 14px/1.5 "\5FAE\8F6F\96C5\9ED1", "arial";
    color: #5a5a5a;
}
body {
    margin: 0 auto;
}
img {
    display: block;
    max-width: 100%;
}
a {
    outline: 0!important;
}
.center {
    width: 1550px;
    margin: 0 auto;
}
.header {
    width: 100%;
    box-shadow: 0 4px 5px rgba(0, 0, 0, .1);
    .box {
        width: 100%;
        padding: 0 2%;
        max-width: 1300px;
        margin: 0 auto;
        .logo {
            float: left;
            line-height: 70px;
            img {
                display: inline-block;
                vertical-align: middle;
            }
        }
        .tit {
            float: right;
            a {
                font-size: 16px;
                line-height: 70px;
            }
        }
    }
}

.p_text {
    width: 100%;
    height: calc(100vh - 70px);
    position: relative;
    .box {
        height: 100%;
        align-items: center;
        display: flex;
        .text {
            max-width: 420px;
            margin: 0 auto;
            background: #fff;
            padding: 40px 50px;
            box-shadow: 0 0px 10px 0 rgba(0, 0, 0, .1);
            p {
                a {
                    float: left;
                    outline: 0;
                    &:first-child {
                        font-size: 24px;
                        color: #000;
                        font-weight: bold;
                        line-height: 100%;
                    }
                    &:first-child +a {
                        font-size: 18px;
                        color: #999;
                        line-height: 100%;
                        margin-top: 6px;
                        margin-left: 20px;
                    }
                }
            }
            form {
                padding-top: 10px;
                input {
                    width: 100%;
                    display: block;
                    outline: 0;
                    border: none;
                    width: 290px;
                    height: 42px;
                    border: 1px solid #ddd;
                    margin-top: 15px;
                    padding: 0 10px;
                }
            }
            i {
                display: block;
                font-style: normal;
                text-align: right;
                margin-top: 15px;
                a {
                    font-size: 13px;
                    outline: 0;
                }
            }
            .sub_btn {
                background: #4664f4;
                text-align: center;
                color: #fff;
            }
        }
    }
}

.header2 {
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    background: #fff;
    box-shadow: 0 4px 5px rgba(0, 0, 0, .1);
    .box {
        width: 100%;
        margin: 0 auto;
        .logo {
            float: left;
            line-height: 50px;
            background: #4664f4;
            width: 220px;
            text-align: center;
            img {
                display: inline-block;
                vertical-align: middle;
            }
        }
        .login_text {
            padding-right: 2%;
            float: right;
            position: relative;
            >a {
                font-size: 14px;
                color: #000;
                line-height: 50px;
                float: left;
                margin: 0 10px;
                img {
                    max-height: 30px;
                    padding-right: 20px;
                }
                &.renw {
                    position: relative;
                    &:after {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 20px;
                        height: 50px;
                        background: url(../img/xiala.png) no-repeat center;
                    }
                }
            }
            .jies {
                width: 230px;
                position: absolute;
                right: 2%;
                top: 55px;
                background: #fff;
                z-index: 99;
                display: none;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
                span {
                    line-height: 50px;
                    border-bottom: 1px solid #ddd;
                    display: block;
                    margin-bottom: 15px;
                    img {
                        float: left;
                        max-height: 30px;
                        margin-top: 10px;
                        margin-left: 20px;
                    }
                    i {
                        font-style: normal;
                        line-height: 50px;
                        float: left;
                        margin-left: 17px;
                    }
                }
                p {
                    line-height: 40px;
                    padding-left: 30px;
                    a {
                        display: block;
                        font-size: 14px;
                        padding-left: 35px;
                    }
                    &.gr {
                        a {
                            background: url(../img/ic18.png) no-repeat left center;
                        }
                    }
                    &.cw {
                        a {
                            background: url(../img/ic19.png) no-repeat left center;
                        }
                    }
                }
                em {
                    background: #f8f9fa;
                    font-style: normal;
                    line-height: 40px;
                    text-align: center;
                    display: block;
                    cursor: pointer;
                    i {
                        font-style: normal;
                        padding-left: 30px;
                        background: url(../img/ic20.png) no-repeat left center;
                    }
                }
            }
        }
    }
}

.main_text {
    width: 100%;
    background: #f0f5fb;
    position: relative;
    .box {
        height: 100%;
        padding-left: 220px;
        padding-top: 50px;
        .fl {
            height: calc(100vh - 50px);
            width: 220px;
            height: 100%;
            background: #22242d;
            position: fixed;
            left: 0;
            top: 50px;
            z-index: 99;
            .menu_a {
                margin-top: 40px;
                padding: 0 8px;
                a {
                    line-height: 45px;
                    display: block;
                    border-radius: 20px;
                    background: #22242d;
                    padding-left: 24px;
                    outline: 0;
                    float: left;
                    width: 100%;
                    i {
                        width: 45px;
                        height: 45px;
                        text-align: center;
                        line-height: 45px;
                        float: left;
                        font-style: normal;
                        font-size: 16px;
                        color: #fff;
                    }
                    span {
                        padding-left: 30px;
                        display: block;
                        font-size: 14px;
                        color: #fff;
                        background: none!important;
                    }
                    &:hover {
                        background: #4664f4;
                    }
                }
                p {
                    font-size: 18px;
                    display: block;
                    border-top: 1px solid #4e5057;
                    color: #fff;
                    margin-top: 30px;
                    padding-top: 30px;
                    margin-bottom: 20px;
                    padding-left: 10px;
                    width: 100%;
                    float: left;
                }
            }
        }
        .fr {
            width: 100%;
            overflow-x: hidden;
            .top {
                padding: 20px;
                .t_fl {
                    background: #fff;
                    width: 60%;
                    float: left;
                    padding: 20px;
                    height: 312px;
                    .fl_text {
                        padding-left: 80px;
                        position: relative;
                        padding-right: 85px;
                        .ren_pic {
                            position: absolute;
                            top: 0;
                            left: 0;
                            img {
                                width: 80px;
                                height: 80px;
                                display: block;
                                margin-top: 15px;
                            }
                        }
                        .text {
                            min-height: 110px;
                            padding-left: 15px;
                            padding-top: 6px;
                            p {
                                line-height: 25px;
                                font-size: 14px;
                                color: #6f7289;
                                margin-top: 2px;
                                display: block;
                                span {
                                    line-height: 25px;
                                    font-size: 14px;
                                    color: #6f7289;
                                    display: inline-block;
                                    margin-left: 15px;
                                    &:first-child {
                                        margin-left: 0;
                                    }
                                }
                                a {
                                    width: 135px;
                                    height: 36px;
                                    background: #4664f4;
                                    display: block;
                                    line-height: 35px;
                                    font-size: 14px;
                                    color: #fff;
                                    text-align: center;
                                    border-radius: 20px;
                                    margin-top: 10px;
                                }
                                a:hover {
                                    background: #768cf7;
                                }
                            }
                        }
                        .ewm {
                            position: absolute;
                            right: 0;
                            top: 0;
                            max-width: 85px;
                            img {
                                display: block;
                                width: 100%;
                            }
                            p {
                                font-size: 14px;
                                color: #666666;
                                display: block;
                                text-align: center;
                                margin-top: 5px;
                            }
                        }
                    }
                    .fl_list {
                        padding-top: 30px;
                        margin-top: 30px;
                        border-top: 1px dashed #dfe3eb;
                        li {
                            width: 33.33%;
                            float: left;
                            span {
                                display: block;
                                text-align: center;
                                font-size: 24px;
                                color: #f27a29;
                                line-height: 40px;
                            }
                            p {
                                display: block;
                                text-align: center;
                                font-size: 14px;
                                color: #7f829b;
                                line-height: 40px;
                            }
                        }
                    }
                }
                .t_fr {
                    width: 40%;
                    float: left;
                    height: 312px;
                    padding-left: 20px;
                    .t_frlist {
                        background: #fff;
                        padding: 20px;
                        height: 100%;
                        span {
                            line-height: 44px;
                            margin-bottom: 10px;
                            position: relative;
                            display: block;
                            border-bottom: 1px dashed #dfe3eb;
                            i {
                                float: left;
                                font-style: normal;
                                font-size: 16px;
                                color: #141b3c;
                                font-weight: 700;
                                padding-left: 6px;
                            }
                            a {
                                float: right;
                                font-size: 12px;
                                &:hover {
                                    color: #4664f4;
                                }
                            }
                        }
                        >a {
                            display: block;
                            padding: 0 10px;
                            line-height: 36px;
                            font-size: 14px;
                            color: #7f829b;
                            position: relative;
                            padding-left: 20px;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            &:after {
                                content: '';
                                position: absolute;
                                left: 5px;
                                top: 50%;
                                margin-top: -2.5px;
                                width: 5px;
                                height: 5px;
                                border-radius: 50%;
                                background: #7f829b;
                            }
                        }
                    }
                }
            }
            .bot {
                padding: 0 20px 0 20px;
                .bot_text {
                    min-height: calc(100vh - 402px);
                    background: #fff;
                    width: 100%;
                    padding: 20px;
                    .bot_h100 {
                        height: 100%;
                        -ms-flex-align: center;
                        align-items: center;
                        display: -ms-flexbox;
                        display: flex;
                        min-height: calc(100vh - 489px);
                        position: relative;
                    }
                }
                .bot_tit {
                    span {
                        border-bottom: 1px dashed #dfe3eb;
                        font-size: 16px;
                        color: #141b3c;
                        font-weight: 700;
                        padding-left: 6px;
                        line-height: 46px;
                        display: block;
                    }
                }
            }
        }
    }
}

.box1_js {
    padding: 2% 20px;
    overflow: hidden;
    padding: 20px 5%;
    .swiper-slide {
        .text {

        }
        .p_img {
            position: absolute;
            left: 0;
            top: 0;
            img {
                display: block;
                margin: 0 auto;
            }
        }
        .s_box {
            padding-left: 300px;
            .tit {
                padding-bottom: 10px;
                span {
                    display: block;
                    i {
                        font-size: 26px;
                        color: #141b3c;
                        float: left;
                        font-style: normal;
                        line-height: 100%;
                        font-weight: bold;
                    }
                    em {
                        font-size: 16px;
                        color: #6f7289;
                        float: left;
                        font-style: normal;
                        line-height: 100%;
                        margin-top: 10px;
                        margin-left: 20px;
                    }
                }
            }
            .list {
                li {
                    width: 25%;
                    float: left;
                    position: relative;
                    padding-left: 50px;
                    min-height: 50px;
                    margin: 25px 0;
                    .p_ic {
                        width: 50px;
                        height: 50px;
                        background: #4664f4;
                        line-height: 50px;
                        text-align: center;
                        border-radius: 8px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        img {
                            display: inline-block;
                            vertical-align: middle;
                        }
                    }
                    p {
                        padding-left: 10px;
                        span {
                            display: block;
                            font-size: 18px;
                            color: #333;
                            font-weight: bold;
                            line-height: 25px;
                        }
                        i {
                            font-style: normal;
                            font-size: 14px;
                            color: #333;
                            display: block;
                            line-height: 25px;
                        }
                    }
                }
            }
        }
    }
    .pagination {
        position: absolute;
        right: 0;
        top: -36px;
        bottom: auto;
        span {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #4664f4;
            margin-left: 8px;
            outline: 0;
        }
    }
}

.fr_bg {
    padding: 20px;
    height: calc(100vh - 50px);
}
.fr_text {
    background: #fff;
    width: 100%;
    //height: 100%;
    overflow-x: hidden;
}

.menu_top {
    border-bottom: 1px solid #dcdee2;
    a {
        padding: 0 20px;
        line-height: 60px;
        display: block;
        float: left;
        &:hover {
            border-bottom: 2px solid #4664f4;
        }
        &.on {
            border-bottom: 2px solid #4664f4;
        }
    }
}

.content_bot {
    width: 100%;
    padding: 30px 1%;
}

.form_list {
    margin-bottom: 20px;
    input {
        width: 150px;
        height: 36px;
        outline: 0;
        line-height: 34px;
        border: none;
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: 0 10px;
        font-size: 14px;
        &#test30 {
            position: relative;
        }
        &:hover {
            border-color: #4664f4;
        }
    }
    select {
        width: 100px;
        outline: 0;
        height: 36px;
        border: none;
        border: 1px solid #ddd;
        border-radius: 5px;
        padding-left: 5px;
        margin: 0;
        &:hover {
            border-color: #4664f4;
        }
    }
    .sub_btn {
        background: #4664f4;
        color: #fff;
        border-color: #4664f4;
        line-height: 34px;
        position: relative;
        margin-left: 10px;
    }
}

.table_btn {
    display: inline-block;
    margin: 5px;
    width: 58px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    @include hover($color, background-color);
    color: #fff!important;
    border-radius: 2px;
    cursor: pointer;
}
.btn_group {
    @extend .table_btn;
    text-align: left;
    position: relative;
    z-index: 1;
    &:after {
        content: '';
        border-style: solid;
        border-color: #fff transparent transparent transparent;
        border-width: 4px 3px 0 3px;
        position: absolute;
        right: 11px;
        top: 14px;
        z-index: 9;
        transition: transform .3s;
    }
    &:hover {
        z-index: 9;
        &:after {
            transform: rotate(180deg);
        }
        ul {
            display: block;
        }
    }
    > span {
        padding-left: 13px;
    }
    > ul {
        position: absolute;
        right: 0;
        top: 100%;
        border: 1px solid #e7e7e7;
        padding: 3px 13px;
        background-color: #fff;
        color: #666;
        display: none;
        li {
            border-top: 1px solid #e7e7e7;
            &:first-child {
                border-top: 0;
            }
            a {
                display: inline-block;
                min-width: 88px;
                line-height: 33px;
            }
        }
    }
}

.word_pack_template {
    position: relative;
    height: 100%;
    padding-top: 154px!important;
    .word_pack_fixed {
        position: absolute;
        z-index: 999;
        width: 100%;
        left: 0;
        top: 0;
        padding: 20px;
        background-color: #fff;
    }
    .word_pack_combination {
        height: 100%;
        overflow-x: hidden;
    }
    .items_tit {
        border-bottom: 1px solid #efefef;
        margin-bottom: 10px;
        padding-bottom: 5px;
        span + span {
            color: #bdbdbd;
            padding-left: 10px;
        }
        i {
            font-style: normal;
        }
    }
    .items {
        margin-bottom: 15px;
        li {
            width: 25%;
            float: left;
        }
    }
    .layui-form-checkbox {
        margin-bottom: 10px;
    }
}
body{
    .flex {
        display: flex;
    }
    .flex_jcc {
        justify-content: center;
    }
    .flex_aic {
        align-items: center;
    }
    .flex_auto {
        flex: auto;
    }
    .layui-progress {
        border-radius: 3px;
        .layui-progress-bar {
            background-color: #4664f4;
            border-radius: 3px;
        }
    }
    .layui-progress-big, .layui-progress-big .layui-progress-bar {
        height: 24px;
        line-height: 24px;
    }
}
body .content_bot{padding: 15px 1%;}
body .layui-form-label{width:150px;}
body .layui-input-inline.w150{width:150px;}
body .layui-input-inline.w200{width:200px;}
body .layui-input-inline.w334{width:334px;}
body .layui-input-inline.w400{width:400px;}
body .layui-input-inline.w600{width:600px;}

body .layui-form-onswitch{border-color:#4664f4; background-color:#4664f4;}
body .layui-form-switch{height:24px; line-height:24px;}
body .blue_btn{border-color:#4664f4; background-color:#4664f4;}
body .layui-btn{height:36px; line-height:36px;}
body .layui-laypage .layui-laypage-curr .layui-laypage-em{background-color:#4664f4;}
body .layui-table-cell{height:auto;}
body .layui-table-view .layui-table td:last-child .layui-table-cell{overflow:initial;}
body .layui-input-block{margin-left:150px;}

body .layui-form-radio>i:hover, body .layui-form-radioed>i{color:#4664f4;}
body .layui-btn, body .layui-form-select dl dd.layui-this, body .layui-laypage .layui-laypage-curr .layui-laypage-em{background-color:#4664f4;}
body .fleft{float:left;}
body .fright{float:right;}
body .showtime{line-height:40px; height:40px;margin-bottom:10px;}
body .content{margin:10px;line-height:30px;}
body .p_text .box .text form .yzcode,body .p_text .box .text form .imgcode{width:150px; float:left; }
body .p_text .box .text form .getyzcode{width:130px;float:left; margin-bottom:10px;margin-left:10px}
body .p_text .box .text form .getimgcode{width:130px;float:left; margin-top:16px;margin-left:10px; height:42px;}
body .mess .error{padding: 5px 0 5px 25px;background: url(../img/error.png) no-repeat 0;color: red;}
body .mess span{display:none;}
body .show_btn{width: 135px;height: 36px;background: #4664f4;display: block;line-height: 35px;font-size: 14px;color: #fff;text-align: center;border-radius: 20px;margin-top: 10px;}
body .layui-table-main{min-height:130px;}
body .bgblue{background-color:#4664f4;}
body .bgred{background-color:red;}
body .layui-table td, body .layui-table th{border-left: none;border-right: none;}


.layui-body {
    border: 20px solid #f6f6f6;
    border-bottom: 0;
    top: 50px;
    bottom: 0;
    padding: 20px;
    background: #fff;
}

.articlecontent{
    padding: 40px 30px;
    line-height: 26px;
}
.notice_title {
    font-size: 16px;
    text-align: center;
    color: #222;
}
.tac {
    text-align: center!important;
}

.upload_btn {
    position: absolute;
    left: 100%;
    top: 0;
    border: 0;
    width: 88px;
    height: 38px;
    line-height: 38px;
    color: #fff;
    background-color: #4664f4;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s;
}
.upload_btn:hover{background-color: #768cf7;}
.layui-table-header .layui-table-cell span{font-weight:bold;}
body .layui-table-view{border-left:none;border-right:none;}
.layui-table-header th{height:50px; line-height:50px;}
.search-form{float:right;}
body .layui-form-checked[lay-skin=primary] i{border-color: #4664f4!important;background-color: #4664f4;}
body .idis{background-color:#f2f2f2;}
body .layui-table tr{height:50px;}


.header2 .box .logo img {
  height: 30px;
  display:inline-block;
  position:relative;
  top:-4px
}

.header2 .box .logo span {
  line-height: 50px;
  font-size: 20px;
  color: #fff;
  margin-left: 10px;
  display:inline-block;
}
.header .box .logo span {
  float: left;
  line-height: 70px;
  font-size: 24px;
  color: #4664f4;
  display: block;
  font-weight: bold;
}

.top-tip{
    background: #f9faff;
    color: #7f829b;
    line-height: 30px;
    padding: 10px 20px;
    border-radius: 5px;
}
.layui-input-inline span{height:38px;line-height:38px;}


@media screen and (max-width: 1020px){
    .box1_js {
        padding: 0 2%;
        .swiper-slide {
            .p_img {
                display: none;
            }
            .s_box {
                padding-left: 0;
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .p_text {
        .box {
            .text {
                padding: 20px 30px;
                width: 85%;
                margin: 0 auto;
                p {
                    a {
                        &:first-child {
                            font-size: .16rem;
                        }
                        &:first-child +a {
                            font-size: .12rem;
                            margin-top: .04rem;
                        }
                    }
                }
                form {
                    padding-top: .03rem;
                    input {
                        width: 100%;
                        font-size: .11rem;
                    }
                }
                i {
                    margin-top: .1rem;
                    a {
                        font-size: .11rem;
                    }
                }
            }
        }
    }
}

.douyin_case {
    body {
        background: url(../img/case_bg.jpg) center / cover;
        color: #fff;
    }
    .center {
        width: 1200px;
    }
    .header {
        margin-bottom: 25px;
        height: 77px;
        background: url(../img/case_title_bg.png) no-repeat center bottom;
        text-align: center;
        padding-top: 30px;
        font-size: 30px;
        font-weight: bold;
        line-height: 1;
    }
    .flex_box {
        display: flex;
        justify-content: space-between;
    }
    .title {
        text-align: center;
        font-size: 20px;
        line-height: 54px;
    }
    .box1 {
        width: 579px;
        height: 390px;
        background: url(../img/case_box1_bg.png) no-repeat center;
        .item {
            display: flex;
            padding: 25px 0;
            &:last-child {
                margin-bottom: 0;
            }
            dl {
                width: 50%;
                height: 115px;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                &::before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: '';
                    width: 1px;
                    height: 115px;
                    background: url(../img/case_box1_line.png);
                }
                &:first-child::before {
                    display: none;
                }
                dt {
                    font-size: 16px;
                }
                dd {
                    font-size: 20px;
                    margin-top: 20px;
                }
            }
        }
    }
    .box2 {
        width: 1195px;
        height: 401px;
        background: url(../img/case_box2_2_bg.png) no-repeat center;
    }
    .box3 {
        width: 363px;
        height: 390px;
        background: url(../img/case_box3_bg.png) no-repeat center;
    }
    .box4 {
        width: 1197px;
        height: 1230px;
        background: url(../img/case_box4_bg.png) no-repeat center;
        padding: 0 35px;
    }
}

@media screen and (max-width: 768px){
    .douyin_case {
        body {
            background: url(../img/case_bg.jpg) center / cover;
            color: #fff;
        }
        .center {
            width: 100%;
            padding: 0 20px;
        }
        .header {
            margin-bottom: 25px;
            height: 77px;
            background: url(../img/case_title_bg.png) no-repeat center bottom;
            text-align: center;
            padding-top: 30px;
            font-size: 30px;
            font-weight: bold;
            line-height: 1;
        }
        .flex_box {
            display: block;
        }
        .title {
            text-align: center;
            font-size: 20px;
            line-height: 54px;
        }
        .box1 {
            width: 100%;
        }
        .box2 {
            display: none;
        }
        .box4 {
            width: 100%;
            padding: 0;
        }
        .layui-table td, .layui-table th {
            font-size: 12px;
        }
        .layui-table-cell {
            padding: 0 5px;
        }
        .laytable-cell-1-0-0 {
            width: 40px;
        }
    }
}

.rank_container {
    width: 1140px;
    margin: 0 auto;
    .wap {
        display: none;
    }
    .dataTables_filter {
        position: absolute;
        right: 0;
        top: -130px;
        font-size: 0;
        input {
            font-size: 14px;
            height: 32px;
            line-height: 32px;
            margin-right: 62px;
            border: 1px solid #00a2e9;
            padding: 0 10px;
            margin-left: 0!important;
        }
        &:after {
            content: '搜索';
            position: absolute;
            right: 0;
            top: 0;
            width: 62px;
            height: 32px;
            line-height: 32px;
            background-color: #00a2e9;
            color: #fff;
            text-align: center;
            font-size: 14px;
        }
    }
    table,
    & th,
    & td {
        border:1px solid #dbdbdb;
        text-align: center;
        line-height: 36px;
    }
    .rank_tips {
        height: 36px;
        line-height: 36px;
        text-align: center;
        border-top: 2px solid #00a2e9;
        background-color: #ececec;
        overflow: hidden;
    }
}
.rank_title {
    background: url(../img/rank_title.png) no-repeat left center;
    height: 34px;
    line-height: 34px;
    padding: 0 30px;
    color: #fff;
    border-bottom: 1px solid #00a2e9;
}
.rank_info {
    display: flex;
    align-items: center;
    height: 68px;
    li {
        margin-right: 30px;
    }
}
.rank_filter {
    height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
        display: inline-block;
        padding: 8px 15px;
        background-color: #00a2e9;
        color: #fff;
        border-radius: 5px;
    }
}
.keywords_search {
    width: 1000px;
    .title {
        color: $color;
        font-size: 32px;
        font-weight: bold;
        margin-top: 40px;
        margin-bottom: 15px;
        text-align: center;
    }
    .results_list {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 15px;
        .item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 70px;
            background-color: #e8f6ff;
            overflow: hidden;
            span {
                margin-left: 5px;
                margin-right: 5px;
                display: inline-block;
                padding: 3px 5px;
                color: #fff;
                font-size: 12px;
            }
            .t1 {
                font-size: 16px;
                color: #000;
                margin-bottom: 10px;
                line-height: 1;
                max-width: 90%;
                @extend .ellipsis;
            }
            .c1 {
                background-color: #64ce51;
            }
            .c2 {
                background-color: #ff9626;
            }
            .c3 {
                background-color: #ea4a60;
            }
            .c4 {
                background-color: #00a3eb;
            }
            .c5 {
                background-color: #1f77de;
            }
        }
    }
    .select_item {
        display: flex;
        .layui-form-label {
            text-align: left;
            padding-left: 0;
            padding-right: 0;
            width: 5em;
        }
        .layui-input-inline {
            flex: 1;
            margin-left: 10px;
            margin-right: 0;
        }
    }
}
.explain {
    background-color: #fff5eb;
    border: 1px solid #fed4a4;
    padding: 16px 16px 16px 46px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1.65;
    letter-spacing: .02em;
}
@media screen and (max-width: 768px) {
    .rank_container {
        width: 100%;
        padding: 0 15px;
        .pc {
            display: none;
        }
        .wap {
            display: block;
        }
        .rank_banner {
            margin-left: -15px;
            margin-right: -15px;
            img {
                width: 100%;
            }
        }
        .rank_title {
            background-image: url(../img/rank_title_wap.png);
        }
        .rank_info {
            height: auto;
            display: block;
            line-height: 2;
            padding: 15px 0;
        }
        .dataTables_filter {
            left: 0;
            top: -195px;
            right: auto;
        }
        .rank_filter {
            height: auto;
            margin-top: 50px;
            flex-direction: column;
            align-items: flex-start;
            height: 130px;
            padding: 20px 0;
        }
        table {
            th:nth-child(2),
            th:nth-child(4),
            th:nth-child(5),
            td:nth-child(2),
            td:nth-child(4),
            td:nth-child(5) {
                display: none;
            }
        }
    }
    .keywords_search {
        .results_list {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

.xm-form-select {
    .xm-cz {
        span {
            line-height: 1;
        }
    }
}


/****************************** media ******************************/
